import { Component } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent {

  ngOnInit(): void {
  }

  pageTitle = [
    {
        bgImage: `assets/img/TripApna/image-header.jpg`,
        title: `About Us`,
    }
]
singleHowItWorkBox = [
    {
        title: `About  Tripapna`,
        paragraph: `We are pleased to introduce ourselves as Tripapna, founded by Tripapna Holiday Private Limited. We are head-quartered in New Delhi since2010 and have pioneered a concept of providing Membership Cards to our customers which would provide our clientele with services in the field of Hotel & Restaurant Industry across India.`,
        paragraph1:`Our company is providing our members with the promise of Lowest Price Guarantee across our listed properties along with 24x7 Customer Support. So when it comes to booking the perfect Hotel / Resort along with Spa, Club-house, Banquets, Gymnasium, Restaurant, Villas, Bungalows & Apartment; our company would excel across all customer expectations. We are equipped with all qualified service staff, supporting staff, and professionals to offer the best customer experience.`,
        paragraph2:`In the past few years, Company boasts to have successfully completed such services in a number of prestigious Hotels / Resorts across the country. These services have also found repeated usage by a varied spectrum of our clients, who have been delighted by our performance. By providing Membership to our customers, we try to bring your Resorts and its facilities closer to the people, so that they notice your existence and also the services that you are ready to provide them. Besides, our Membership card can get them a discount on other facilities like Rooms / Restaurant / Spa / Travel Desk, etc.`
    },
  
]

}
