import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HomeserviceService } from 'src/app/Services/homeservice.service';
import ValidateForm from 'src/app/helper/validateForm';

@Component({
  selector: 'app-profle-auth-login',
  templateUrl: './profle-auth-login.component.html',
  styleUrls: ['./profle-auth-login.component.scss']
})
export class ProfleAuthLoginComponent {

  loginForm!: FormGroup;

  constructor(private fb: FormBuilder, private homeservice: HomeserviceService, private router: Router) { }

  ngOnInit(): void {
this.homeservice.reloadSeller();
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]

    })

  }

  onLogin() {
    if (this.loginForm.valid) {
      //send object database
      this.homeservice.userLogin(this.loginForm.value)
        .subscribe({
          next: (res) => {
            console.log(JSON.stringify(res.data.original.user.name))
            console.log(JSON.stringify(res.data.original.user.email))
            //this.homeservice.SetAuthUser(res.data.original.user);
            localStorage.setItem('userlogin',JSON.stringify(res.data.original.user))
            //JSON.parse(localStorage.getItem('username'));
           // localStorage.setItem('email',res.data.original.user.email)
       
            alert("Successfull")
            this.loginForm.reset();
            this.router.navigate([''])
          },
          // error: (err) => {
          //   alert(err.error.message)
          // }
        })
      console.log(this.loginForm.value)
    }
    else {
      //error using totaser
      ValidateForm.validateAllFormFileds(this.loginForm)
      alert("Your form is invalid")
    }
  }

  //   private validateAllFormFileds(formGroup:FormGroup){
  //  Object.keys(formGroup.controls).forEach(filed=>{
  //   const control=formGroup.get(filed);
  //   if(control instanceof FormControl){
  //     control.markAsDirty({
  //       onlySelf:true
  //     });
  //   }
  //   else if(control instanceof FormGroup){
  //     this.validateAllFormFileds(control)
  //   }
  //  })
  //   }
  pageTitle = [
    {
      bgImage: `assets/img/TripApna/image-header.jpg`,
      title: `Login`
    }
  ]

}
