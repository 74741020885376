<app-homeone-banner></app-homeone-banner>

<div class="deals-area pt-100 pb-70">
    <app-featured-deals></app-featured-deals>
</div>

<div class="deals-area pb-70">
    <app-deals></app-deals>
</div>

<div class="popular-store-area pb-100">
    <app-popular-store></app-popular-store>
</div>

<div class="deals-area pb-100">
    <app-todays-hot-deals></app-todays-hot-deals>
</div>

<app-join-now></app-join-now>

<div class="deals-area pt-100 pb-70">
    <app-popular-deals></app-popular-deals>
</div>

<div class="partner-area">
    <app-partner-style-one></app-partner-style-one>
</div>

<div class="deals-area pt-100 pb-70">
    <app-featured-discount-deals></app-featured-discount-deals>
</div>

<div class="blog-area pb-70">
    <app-blog></app-blog>
</div>

<app-why-choose-us></app-why-choose-us>