import { Component } from '@angular/core';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacypolicyComponent {
  ngOnInit(): void {
  }

  pageTitle = [
    {
        bgImage: `assets/img/TripApna/image-header.jpg`,
        title: `Privacy Policy for Tripapna`,
    }
]
singleHowItWorkBox = [
    {
        title: `Privacy Policy for Tripapna`,
        paragraph: `We at Tripapna take your privacy seriously and thus have developed this Privacy Policy. The Privacy Policy governs Tripapna and specifies the method in which we handle the personal information of our valuable clients. We assure that we will strictly adhere to these norms, maintain and use the information responsibility.`,
        paragraph1:`The Privacy Policy is effective after a new user’s acceptance and subject to change from time to time. While using the services of Tripapna directly, or through any other site linked or connected through GottaOffer, you shall be bound by the Policy.`,
       title1:`We may gather information that includes the following:`,
       titl1paragraph:'Your name, e-mail address, correspondence address, mobile number, and physical contact information.',
       title2:`Your Credit Card details`,
       titleparagraph:`Communications and messages of correspondence between the user and Tripapna.`,
       titleparagraph1:'Transactional information restricted to your activities on the site, including buying, selling, generating a password that is related to your account.'
    },
]
}
