<app-homethree-banner></app-homethree-banner>

<div class="deals-area pt-100 pb-70">
    <app-property-type-deals></app-property-type-deals>
</div>

<div class="deals-area pb-70">
    <app-categories-with-deals></app-categories-with-deals>
</div>

<div class="deals-area pb-70">
    <app-deals></app-deals>
</div>

<div class="deals-area pb-100">
    <app-todays-hot-deals></app-todays-hot-deals>
</div>

<div class="popular-store-area pb-100">
    <app-popular-store></app-popular-store>
</div>

<app-join-now></app-join-now>

<div class="deals-area pt-100 pb-70">
    <app-popular-deals></app-popular-deals>
</div>

<div class="partner-area bg-ffffff ptb-100">
    <app-partner-style-two></app-partner-style-two>
</div>

<div class="deals-area pt-100 pb-70">
    <app-featured-discount-deals></app-featured-discount-deals>
</div>

<div class="blog-area pb-70">
    <app-blog></app-blog>
</div>

<app-why-choose-us></app-why-choose-us>