import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-partner-style-one',
    templateUrl: './partner-style-one.component.html',
    styleUrls: ['./partner-style-one.component.scss']
})
export class PartnerStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: `Our Branding Partners`
        }
    ]
    partnerItem = [
        {
            img: `assets/img/TripApna/brandingimage/radi.jpg`
        },
        {
            img: `assets/img/TripApna/brandingimage/hyatt1.jpg`
        },
        {
            img: `assets/img/TripApna/brandingimage/itc.jpg`
        },
        {
            img: `assets/img/TripApna/brandingimage/jw.jpg`
        },
        {
            img: `assets/img/TripApna/brandingimage/lalit.jpg`
        },
        {
            img: `assets/img/TripApna/brandingimage/leela.png`
        }
    ]

}