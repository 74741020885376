import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent = [
        {
            title: `Best Deals In This Week`,
            categoriesList: [
                {
                    icon: `bx bx-hotel`,
                    title: `Hotel & Resort`,
                    link: `search-page`
                },
                {
                    icon: `bx bxs-car-mechanic`,
                    title: `Automotive`,
                    link: `search-page`
                },
                {
                    icon: `bx bx-drink`,
                    title: `Food & Drink`,
                    link: `search-page`
                },
                {
                    icon: `bx bx-bulb`,
                    title: `Electronics`,
                    link: `search-page`
                },
                {
                    icon: `bx bx-clinic`,
                    title: `Health & Fitness`,
                    link: `search-page`
                },
                {
                    icon: `bx bxs-plane`,
                    title: `Gateways`,
                    link: `search-page`
                }
            ],
            buttonText: `View All Categories`,
            buttonLink: `categories`
        }
    ]
    mainBannerBox = [
        {
            image: `assets/img/banner-img1.jpg`,
            title: `Unique Stays Around The World`,
            paragraph: `Tents, treehouses, and more—these spaces are more than just a place to sleep.`,
            buttonText: `Show All Hotel & Resort`,
            buttonLink: `search-page`
        },
        {
            image: `assets/img/banner-img2.jpg`,
            title: `Find the Best Auto Service and Repair Companies`,
            paragraph: `Tents, treehouses, and more—these spaces are more than just a place to sleep.`,
            buttonText: `Show All Repair Companies`,
            buttonLink: `search-page`
        },
        {
            image: `assets/img/banner-img3.jpg`,
            title: `Find the Best Restaurant`,
            paragraph: `Tents, treehouses, and more—these spaces are more than just a place to sleep.`,
            buttonText: `Show All Restaurant`,
            buttonLink: `search-page`
        },
        {
            image: `assets/img/banner-img4.jpg`,
            title: `Top 10 Electronic Companies in USA`,
            paragraph: `Tents, treehouses, and more—these spaces are more than just a place to sleep.`,
            buttonText: `Show All Companies`,
            buttonLink: `search-page`
        },
        {
            image: `assets/img/banner-img5.jpg`,
            title: `Find the Best Gym Center`,
            paragraph: `Tents, treehouses, and more—these spaces are more than just a place to sleep.`,
            buttonText: `Show All Gym Center`,
            buttonLink: `search-page`
        },
        {
            image: `assets/img/banner-img6.jpg`,
            title: `Travel the Whole World`,
            paragraph: `Tents, treehouses, and more—these spaces are more than just a place to sleep.`,
            buttonText: `Show All Gateways`,
            buttonLink: `search-page`
        }
    ]

    homeSlides: OwlOptions = {
		loop: true,
		nav: false,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		items: 1,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				autoHeight: true
			},
			992: {
				autoHeight: false
			}
		}
    }

}