<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="deals-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 col-lg-8 col-md-12">
                <div class="marxa-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <p>We found <span class="count">6</span> products available for you</p>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <select>
                                <option>Default</option>
                                <option>Popularity</option>
                                <option>Latest</option>
                                <option>Price: low to high</option>
                                <option>Price: high to low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" *ngFor="let Content of singleDealsBox | paginate: { itemsPerPage: 12, currentPage: dealsGrid }">
                        <div class="single-deals-box">
                            <div class="deals-image">
                                <a routerLink="/{{Content.detailsLink}}" class="d-block">
                                    <img [src]="Content.image" alt="image">
                                </a>
                                <a href="javascript:void(0)" class="bookmark-save"><i class='bx bx-heart'></i></a>
                                <div class="discount" *ngIf="Content.discount">{{Content.discount}}</div>
                                <a routerLink="/{{Content.detailsLink}}" class="show-coupon-code" target="_blank" ngxClipboard [cbContent]="[Content.couponCode]" [container]="container" tooltip="Click to Copy" placement="right" show-delay="100" *ngIf="Content.couponCode">Show Coupon Code</a>
                            </div>
                            <div class="deals-content">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="rating">
                                        <i class="{{Icon.icon}}" *ngFor="let Icon of Content.ratingStar"></i>
                                        <span class="count">({{Content.ratingCount}})</span>
                                    </div>
                                    <div class="status">
                                        <span *ngIf="Content.trending"><i class='bx bx-trending-up'></i> {{Content.trending}}</span>
                                    </div>
                                </div>
                                <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                                <span class="location"><i class='bx bxs-map'></i> {{Content.location}}</span>
                            </div>
                            <div class="box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span class="new-price">{{Content.newPrice}}</span>
                                        <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                                    </div>
                                    <a routerLink="/{{Content.detailsLink}}" class="details-btn">View Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="dealsGrid = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Category</h3>
                        <ul>
                            <li><a routerLink="/"><i class='bx bx-happy-beaming'></i> Beauty</a></li>
                            <li><a routerLink="/"><i class='bx bxs-t-shirt'></i> Clothing</a></li>
                            <li><a routerLink="/"><i class='bx bx-laptop'></i> Computers</a></li>
                            <li><a routerLink="/"><i class='bx bx-devices'></i> Electronics</a></li>
                            <li><a routerLink="/"><i class='bx bx-chair'></i> Furniture</a></li>
                            <li><a routerLink="/"><i class='bx bx-first-aid'></i> Health</a></li>
                            <li><a routerLink="/"><i class='bx bx-camera'></i> Photography</a></li>
                            <li><a routerLink="/"><i class='bx bx-bowling-ball'></i> Baby and Kids</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_stores">
                        <h3 class="widget-title">Stores</h3>
                        <ul>
                            <li>
                                <input type="checkbox" id="excavator" name="excavator" value="Excavator">
                                <label for="excavator">Excavator <span class="count">(0)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="theBird" name="theBird" value="The Bird">
                                <label for="theBird">The Bird <span class="count">(1)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="tripleOne" name="tripleOne" value="Triple One">
                                <label for="tripleOne">Triple One <span class="count">(2)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="hiTech" name="hiTech" value="Hi-Tech">
                                <label for="hiTech">Hi-Tech <span class="count">(3)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="desire" name="desire" value="Desire">
                                <label for="desire">Desire <span class="count">(4)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="lorem" name="lorem" value="Lorem">
                                <label for="lorem">Lorem <span class="count">(5)</span></label>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_looking_for">
                        <h3 class="widget-title">I'm looking for</h3>
                        <ul>
                            <li>
                                <input type="radio" id="all" name="lookingFor" value="lookingFor" checked>
                                <label for="all">All</label>
                            </li>
                            <li>
                                <input type="radio" id="dealsOnly" name="lookingFor" value="lookingFor">
                                <label for="dealsOnly">Deals Only</label>
                            </li>
                            <li>
                                <input type="radio" id="couponsOnly" name="lookingFor" value="lookingFor">
                                <label for="couponsOnly">Coupons Only</label>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_custom_ads">
                        <a href="#" class="d-block" target="_blank">
                            <img src="assets/img/custom-ads1.jpg" alt="image">
                        </a>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>