<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="how-it-works-area ptb-100">
    <div class="container">
        <div class="single-how-it-works-box" *ngFor="let Content of singleHowItWorkBox;">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 how-it-works-content">
                    <h3>{{Content.title}}</h3>
                    <p>{{Content.paragraph}}</p>
                    <p>{{Content.paragraph1}}</p>
                    <p>{{Content.paragraph2}}</p>
                    <p>{{Content.paragraph3}}</p>
                    <p>{{Content.paragraph4}}</p>
                    <p>{{Content.paragraph5}}</p>
                    <p>{{Content.paragraph6}}</p>
                    <p>{{Content.paragraph7}}</p>
                    <p>{{Content.paragraph8}}</p>
                    <p>{{Content.paragraph9}}</p>
                    <p>{{Content.paragraph10}}</p>
                    <p>{{Content.paragraph11}}</p>
                    <p>{{Content.paragraph12}}</p>
                    <p>{{Content.paragraph13}}</p>
                    <p>{{Content.paragraph14}}</p>
                    <p>{{Content.paragraph15}}</p>
                   
                </div>
                
            </div>
        </div>
    </div>
</div>