<app-hometwo-banner></app-hometwo-banner>

<div class="deals-area bg-black">
    <app-best-deals></app-best-deals>
</div>

<div class="deals-area pt-70 pb-100">
    <app-deals-with-tabs></app-deals-with-tabs>
</div>

<div class="deals-area pb-100">
    <app-todays-hot-deals></app-todays-hot-deals>
</div>

<div class="popular-store-area pb-100">
    <app-popular-store></app-popular-store>
</div>

<app-join-now></app-join-now>

<div class="deals-area pt-100 pb-70">
    <app-popular-deals></app-popular-deals>
</div>

<div class="partner-area">
    <app-partner-style-one></app-partner-style-one>
</div>
<br>
<!-- <div class="deals-area pt-100 pb-70">
    <app-featured-discount-deals></app-featured-discount-deals>
</div> -->

<div class="blog-area pb-70">
    <app-blog></app-blog>
</div>

<app-why-choose-us></app-why-choose-us>