<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="dashboard-tabs-list">
            <ul>
                <li><a routerLink="/dashboard">Dashboard</a></li>
                <li><a routerLink="/dashboard-profile">Profile</a></li>
                <li><a routerLink="/dashboard-store">Store</a></li>
                <li><a routerLink="/dashboard-coupons">Coupons</a></li>
                <li><a routerLink="/dashboard-deals" class="active">Deals</a></li>
                <li><a routerLink="/dashboard-vouchers">Vouchers</a></li>
                <li><a routerLink="/dashboard-agents">Agents</a></li>
                <li><a routerLink="/">Logout</a></li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="dashboard-profile-box">
                    <div class="image">
                        <img src="assets/img/user1.jpg" alt="image">
                        <span>Jason Smith</span>
                    </div>
                    <p>You have <strong>$19,321</strong> dollars left, <a routerLink="/">earn mores</a></p>
                    <a routerLink="/" class="logout">Log Out</a>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="dashboard-stats">
                    <div class="alert alert-info">Submission of the deal is charged $7.99</div>
                    <form>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Title <span>*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Description <span>*</span></label>
                                    <textarea cols="30" rows="5" class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Featured Image <span>*</span></label>
                                    <input type="file" multiple>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Category Name <span>*</span></label>
                                    <select>
                                        <option>-Select-</option>
                                        <option>Excavator</option>
                                        <option>The Bird</option>
                                        <option>Triple One</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Name <span>*</span></label>
                                    <select>
                                        <option>-Select-</option>
                                        <option>Excavator</option>
                                        <option>The Bird</option>
                                        <option>Triple One</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Location <span>*</span></label>
                                    <select>
                                        <option>-Select-</option>
                                        <option>USA</option>
                                        <option>Dubai</option>
                                        <option>New York</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Start Date</label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Expire Date <span>*</span></label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Deal Items <span>*</span></label>
                                    <input type="text" class="form-control">
                                    <div class="description">Input number of deal items or services which will be available for purchase.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Deal Item Vouchers</label>
                                    <textarea cols="30" rows="5" class="form-control"></textarea>
                                    <div class="description">If you want to serve predefined vouchers instead of random generated ones, input them here one in a row and make sure that you have same amount of these vouchers as the number of items.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Deal Voucher Expire Date</label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Deal Images</label>
                                    <input type="file" multiple>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Affiliate Link</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Short Description <span>*</span></label>
                                    <textarea cols="30" rows="5" class="form-control"></textarea>
                                    <div class="description">Input description which will appear in the deal single page sidebar.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Deal Price <span>*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Deal Sale Price <span>*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Deal Discount <span>*</span></label>
                                    <input type="text" class="form-control">
                                    <div class="description">Input discount percentage number with the % sign after number ( auto updated by the sale price change in the previous field ).</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Deal Locations</label>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Deal Type <span>*</span></label>
                                    <select>
                                        <option>-Select-</option>
                                        <option>Excavator</option>
                                        <option>The Bird</option>
                                        <option>Triple One</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Finish</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>