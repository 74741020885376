import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HomeserviceService } from 'src/app/Services/homeservice.service';

@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class TopHeaderComponent implements OnInit {

    location: any;
    containerClass: any;
    user:any;

    constructor(
        private router: Router,
        location: Location,private homeservice:HomeserviceService
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/index-3' || this.location == '/locations' || this.location == '/blog-details' || this.location == '/checkout'){
                    this.containerClass = 'container';
                } else {
                    this.containerClass = 'container-fluid';
                }
            }
        });
    }

    ngOnInit(): void {
        //let data=JSON.parse(localStorage.getItem('username'))

    if(localStorage.getItem('userlogin')){
        
    }
        let data=localStorage.getItem('userlogin');
        console.log(data);
        if(data!=null){
            this.user=JSON.parse(data);
            console.log(this.user);
        }
      
    }

    logout() {
        localStorage.removeItem ('userlogin');
      
        this.router.navigate(['/login']);
      }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}