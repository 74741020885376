<div class="navbar-area navbar-style-two bg-black" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/white-logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Home</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>
                            <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>
                            <li class="nav-item"><a routerLink="/index-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/search-page" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">All Discounts</a></li>
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Deals</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="row">
                                    <div class="col">
                                        <h6 class="submenu-title">Top Locations</h6>
                                        <ul class="megamenu-submenu">
                                            <li><a routerLink="/search-page">South Island <span class="count">6</span></a></li>
                                            <li><a routerLink="/search-page">Paris <span class="count">5</span></a></li>
                                            <li><a routerLink="/search-page">Bora Bora <span class="count">4</span></a></li>
                                            <li><a routerLink="/search-page">London <span class="count">3</span></a></li>
                                            <li><a routerLink="/search-page">Maui <span class="count">2</span></a></li>
                                            <li><a routerLink="/search-page">Tahiti <span class="count">1</span></a></li>
                                        </ul>
                                    </div>
                                    <div class="col">
                                        <h6 class="submenu-title">Top Categories</h6>
                                        <ul class="megamenu-submenu">
                                            <li><a routerLink="/search-page">Accessories <span class="count">1</span></a></li>
                                            <li><a routerLink="/search-page">Entertainment <span class="count">2</span></a></li>
                                            <li><a routerLink="/search-page">Beauty <span class="count">3</span></a></li>
                                            <li><a routerLink="/search-page">Clothing <span class="count">4</span></a></li>
                                            <li><a routerLink="/search-page">Computers <span class="count">5</span></a></li>
                                            <li><a routerLink="/search-page">Photography <span class="count">6</span></a></li>
                                        </ul>
                                    </div>
                                    <div class="col">
                                        <h6 class="submenu-title">The Best Deal</h6>
                                        <a routerLink="/search-page" class="p-0 d-inline-block">
                                            <img src="assets/img/mega-menu-image.jpg" alt="image">
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/coupons" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coupons</a></li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Stores</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/all-stores" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Stores</a></li>
                            <li class="nav-item"><a routerLink="/stores-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Stores Details</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/locations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Locations</a></li>
                            <li class="nav-item"><a routerLink="/categories" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Categories</a></li>
                            <li class="nav-item"><a routerLink="/how-it-works" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">How It Works</a></li>
                            <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/products-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products List</a></li>
                                    <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                    <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                                    <li class="nav-item"><a routerLink="/products-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                            <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login/Register</a></li>
                            <li class="nav-item"><a routerLink="/customer-service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Customer Service</a></li>
                            <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                            <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">My Account <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Profile</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-store" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Store</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-coupons" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Coupons</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-deals" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Deals</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                            <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                            <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <div class="search-icon" (click)="toggleClass2()">
                            <i class='bx bx-search'></i>
                        </div>
                    </div>
                    <div class="option-item">
                        <a routerLink="/cart" class="wishlist-btn"><i class='bx bx-heart'></i></a>
                    </div>
                    <div class="option-item">
                        <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>

<div class="search-overlay" [class.active]="classApplied2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass2()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search deals, coupons & stores">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>