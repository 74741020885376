<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of singleBlogPost | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/{{Content.detailsLink}}" class="d-block">
                            <img [src]="Content.image" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <ul class="meta">
                            <li><i class="bx bx-purchase-tag"></i> <a routerLink="/{{Content.tagLink}}">{{Content.tag}}</a></li>
                        </ul>
                        <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                        <p>{{Content.paragraph}}</p>
                        <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.detailsText}} <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="blogGrid = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>