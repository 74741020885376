import { Component, OnInit } from '@angular/core';
import { HomeserviceService } from 'src/app/Services/homeservice.service';

@Component({
    selector: 'app-popular-store',
    templateUrl: './popular-store.component.html',
    styleUrls: ['./popular-store.component.scss']
})
export class PopularStoreComponent implements OnInit {

    hotellist:any;
    constructor(private homeservice:HomeserviceService) { }

    ngOnInit(): void {
        this.getHotelList();
    }

    getHotelList(){
        this.homeservice.hotellist()
        .subscribe(result=>{
   this.hotellist=result;
   console.log(this.hotellist.data)
        });
    }
    popularStoreContent = [
        {
            // image: `assets/img/popular-store-bg.jpg`,
            image: `assets/img/TripApna/hotel.jpg`,
            title: `Popular Hotel`,
            paragraph: `Find top hotel Membership here!!!`,
            buttonText: `View All Hotels`,
            buttonLink: `all-stores`
        }
    ]
    popularStoreList = [
        {
            image: `assets/img/popular-store/store1.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store2.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store3.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store4.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store5.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store6.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store7.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store8.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store9.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store10.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store11.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store12.png`,
            link: `stores-details`
        }
    ]

}