import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeserviceService } from 'src/app/Services/homeservice.service';

@Component({
    selector: 'app-deals-details-page',
    templateUrl: './deals-details-page.component.html',
    styleUrls: ['./deals-details-page.component.scss']
})
export class DealsDetailsPageComponent implements OnInit {

    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    myInterval: any;
    productdata:any;

    logindetails:any;

    constructor(private activeroute:ActivatedRoute,private homeservice:HomeserviceService) { }

    ngOnInit():void {

        this.logindetails=localStorage.getItem('userlogin')
        console.log(this.logindetails)
        this.myInterval = setInterval(() => {
            this.commingSoonTime();
        }, 0);
 

        let productid=this.activeroute.snapshot.paramMap.get('productId')
        console.warn(productid)
        productid &&this.homeservice.gethotelpackagebyid(productid).subscribe((result)=>{
       console.warn(result)
       this.productdata=result.data
       console.log(this.productdata.package_item)
        })

    }
   
    

    pageTitle = [
        {
            bgImage: `assets/img/page-title/page-title4.jpg`,
            title: `The Start Hotel, Casino`,
        }
    ]

    commingSoonTime = () => {
        const endTimeParse = (Date.parse('January 20, 2022 17:00:00 PDT')) / 1000;
        const now = new Date();
        const nowParse = (Date.parse(now.toString()) / 1000);
        const timeLeft = endTimeParse - nowParse;
        const days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < 10) { hours = 0 + hours; }
        if (minutes < 10) { minutes = 0 + minutes; }
        if (seconds < 10) { seconds = 0 + seconds; }
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

    dealsDetailsImageSlides: OwlOptions = {
		items: 1,
		nav: true,
		loop: true,
		dots: false,
		autoplay: true,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		]
    }

}