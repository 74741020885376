<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <!-- <div class="page-title-area"  style="background-color: #ff9500 !important;"> -->
        <div class="container">
            <h1>Login</h1>
        </div>
    </div>
    
    <div class="profile-authentication-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6" style="float:none;margin:auto;">
                    <div class="login-form">
                        <h2 style="text-align: center;">Login</h2>
                        <form [formGroup]="loginForm" >
                            <div class="form-group">
                                <label>email</label>
                                <input type="text" formControlName="email"  class="form-control">
                                <small *ngIf="loginForm.controls['email'].dirty &&loginForm.hasError('required','email')" class="text-danger">* Username is reqired</small>
                            </div>
                          
                            <div class="form-group">
                                <label>Password</label>
                                <input type="password"  formControlName="password"   class="form-control">
                                <small *ngIf="loginForm.controls['password'].dirty &&loginForm.hasError('required','password')" class="text-danger">* Password is reqired</small>
                            </div>
                          
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                    <p>
                                        <input type="checkbox" id="test2">
                                        <label for="test2">Remember me</label>
                                    </p>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                    <a routerLink="/" class="lost-your-password">Lost your password?</a>
                                </div>
                            </div>
                            <button type="submit" (click)="onLogin()">Log In</button>
                        </form>
                    </div>
                </div>
             
            </div>
        </div>
    </div>