import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-join-now',
    templateUrl: './join-now.component.html',
    styleUrls: ['./join-now.component.scss']
})
export class JoinNowComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    joinNowContent = [
        {
            title: `Join Tripapna and Get Discounts on Flight, Hotel, taxi & Hotel Membership!`,
            paragraph: `We are pleased to introduce ourselves as Tripapna, founded by Tripapna Holiday Private Limited. We are head-quartered in New Delhi since2010 and have pioneered a concept of providing Membership Cards to our customers which would provide our clientele with services in the field of Hotel & Restaurant Industry across India.`,
            buttonText: `Join Now`,
            buttonLink: `contact`
        }
    ]

}