import { HttpClient,HttpResponse,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AUTH_ID } from '../components/app.constant';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HomeserviceService {
  private httpHeaders: HttpHeaders;
  apiUrl ='https://couponapi.bestsoftwareagency.com/api/'
  user: any | undefined;
  isSellerLoggedIn=new BehaviorSubject<boolean>(false)
  constructor(private http:HttpClient,private router:Router) { 
    this.httpHeaders = new HttpHeaders({ 'content-type': 'application/json' });
  }
   
  hoteldataget(){
    
    return this.http.get<any>(this.apiUrl)
  }

  userSingUp(data:any){
    // return this.http.post<any>(this.apiUrl+'user-register',data) 
    return this.http.post<any>(`${this.apiUrl}user-register`,data) 
  }
  userLogin(data:any){
    // return this.http.post(this.apiUrl+'login',data)
    // .subscribe((result:any)=>{
    //   localStorage.setItem("token",result.access_token)
    // })
  //}
 
    //return this.http.post<any>(`${this.apiUrl}login`,data) 
    let results= this.http.post<any>(this.apiUrl+'login',data) 
    console.log(results)
    return results;
}

reloadSeller(){
  if(localStorage.getItem('userlogin')){
    this.isSellerLoggedIn.next(true);
    this.router.navigate([''])
    
  }
}

hotellist(){
  let results=this.http.get(this.apiUrl+'hotel_list')
 
  return results;
}

getCoupon(){
  let results=this.http.get(this.apiUrl+'getCoupon')
  //console.warn(results)
  return results;
}

getpackage(type:string){
  let results=this.http.get(this.apiUrl+'getPackage?type='+type)
  //console.warn(results)
  return results;
}



gethotelpackagebyid(package_id:any){
  console.log(package_id);
  let results=this.http.post<any>(this.apiUrl+'packagesDetails?package_id='+package_id+'',{observe: 'response', headers: this.httpHeaders });
  return results;
}

gethotelpackagelistbyid(hotel_id:any){
  let results=this.http.post<any>(this.apiUrl+'hotel_package?hotel_id='+hotel_id+'',{observe: 'response', headers: this.httpHeaders });
  return results;
}





}

