import { Component } from '@angular/core';

@Component({
  selector: 'app-cancellationrefundtripapna',
  templateUrl: './cancellationrefundtripapna.component.html',
  styleUrls: ['./cancellationrefundtripapna.component.scss']
})
export class CancellationrefundtripapnaComponent {
  ngOnInit(): void {
  }

  pageTitle = [
    {
        bgImage: `assets/img/TripApna/image-header.jpg`,
        title: `Cancellation & Refund`,
    }
]
singleHowItWorkBox = [
    {
        title: `Cancellation & Refund Tripapna`,
        paragraph: `Tripapna is a platform that promotes various hotel offers offered by alternate famous hotel chain providers on their respective health related services.`,
        paragraph1:`These rules apply unless otherwise stated in the fine print pertaining to the deal:`,
        paragraph2:`Voucher will expire on the date specified on the voucher`,
        paragraph3:`Not valid for cash back`,
        paragraph4:`Cannot be combined with other offers`,
        paragraph5:`Providing voucher code is mandatory to avail deal`,
        paragraph6:`Booking, reservations and amendments are subject to availability`,
        paragraph7:`Must be redeemed in a single visit (unless specified otherwise)`,
        paragraph8:` Vouchers can be redeemed only after due verification of the customer (including photo ID proof, if required)`,
        paragraph9:` All local/national laws and rules - dress code, consumption of intoxicants etc - that apply to regular customers, apply to Tripapna voucher holders too`
    },
  
]
}
