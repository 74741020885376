import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HomeserviceService } from 'src/app/Services/homeservice.service';
import ValidateForm from 'src/app/helper/validateForm';

@Component({
    selector: 'app-profile-authentication-page',
    templateUrl: './profile-authentication-page.component.html',
    styleUrls: ['./profile-authentication-page.component.scss']
})
export class ProfileAuthenticationPageComponent implements OnInit {

    signUpForm!:FormGroup;
    constructor(private homeservice:HomeserviceService,private router:Router,private fb:FormBuilder) { }

    ngOnInit(): void {

        this.signUpForm=this.fb.group({
            name:['',Validators.required],
            email:['',Validators.required],
            mobile:['',Validators.required],
            password:['',Validators.required],
            c_password:['',Validators.required],

        })
    }

    onSignup(){
        console.log(this.signUpForm.value)
        if(this.signUpForm.valid){
            //send object database
      this.homeservice.userSingUp(this.signUpForm.value)
      .subscribe({
        next:(res=>{
            alert("successfullly")
            this.signUpForm.reset();
            this.router.navigate(['login']);
        })
        
      })
            console.log(this.signUpForm.value)
          }
          else{
            //error using totaser
            ValidateForm.validateAllFormFileds(this.signUpForm)
            alert("Your form is invalid")
          } 
    }

    // private validateAllFormFileds(formGroup:FormGroup){
    //     Object.keys(formGroup.controls).forEach(filed=>{
    //      const control=formGroup.get(filed);
    //      if(control instanceof FormControl){
    //        control.markAsDirty({
    //          onlySelf:true
    //        });
    //      }
    //      else if(control instanceof FormGroup){
    //        this.validateAllFormFileds(control)
    //      }
    //     })
    //      }

  
   
    pageTitle = [
        {
            bgImage: `assets/img/TripApna/image-header.jpg`,
            title: `Register`
        }
    ]

}