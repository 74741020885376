<div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle;">
        <h2>{{Title.title}}</h2>
        <p>{{Title.paragraph}}</p>
    </div>

    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of singleProductsBox;">
            <div class="single-products-box">
                <div class="products-image">
                    <a routerLink="/{{Content.detailsLink}}">
                        <img [src]="Content.image" class="main-image" alt="image">
                    </a>
                    <div class="sale-tag" *ngIf="Content.sale">{{Content.sale}}</div>
                    <div class="new-tag" *ngIf="Content.new">{{Content.new}}</div>
                </div>
                <div class="products-content">
                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                    <div class="price">
                        <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                        <span class="new-price">{{Content.newPrice}}</span>
                    </div>
                    <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                </div>
            </div>
        </div>
    </div>
</div>