import { Component, OnInit } from '@angular/core';
import { HomeserviceService } from 'src/app/Services/homeservice.service';

@Component({
    selector: 'app-stores-page',
    templateUrl: './stores-page.component.html',
    styleUrls: ['./stores-page.component.scss']
})
export class StoresPageComponent implements OnInit {
    hotellist: any;
    constructor(private homeservice:HomeserviceService) { }

    ngOnInit(): void {
       
    this.getHotelList();
       
    }

    getHotelList(){
        this.homeservice.hotellist()
        .subscribe(result=>{
   this.hotellist=result;
   console.log(this.hotellist.data)
        });
    }

    pageTitle = [
        {
            bgImage: `assets/img/TripApna/image-header.jpg`,
            title: `Hotel List`
        }
    ]
    popularStoreList = [
        {
            image: `assets/img/popular-store/store1.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store2.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store3.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store4.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store5.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store6.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store7.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store8.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store9.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store10.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store11.png`,
            link: `stores-details`
        },
        {
            image: `assets/img/popular-store/store12.png`,
            link: `stores-details`
        }
    ]



}