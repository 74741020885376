<div class="container">
    <div class="popular-store-inner">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="popular-store-content" *ngFor="let Content of popularStoreContent;" style="background-image: url({{Content.image}});">
                    <div class="content">
                        <h2>{{Content.title}}</h2>
                        <p>{{Content.paragraph}}</p>
                        <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="popular-store-list">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4 col-6 col-md-4" *ngFor="let Item of hotellist?.data;">
                            <div class="item">
                                <a routerLink="stores-details/{{Item.id}}">
                                    <img [src]="Item.logo" alt="image">
                                </a>
                                <span class="location"><i class='bx bxs-map'></i> {{Item.location}}</span>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>