import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-how-it-works-page',
    templateUrl: './how-it-works-page.component.html',
    styleUrls: ['./how-it-works-page.component.scss']
})
export class HowItWorksPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: `assets/img/TripApna/image-header.jpg`,
            title: `Rules of use`,
        }
    ]
    singleHowItWorkBox = [
        {
            title: `Rules of use:`,
            paragraph: `Please read carefully to avoid any misunderstanding.`,
            paragraph1: `1.This package cannot be used without activation. For activation of this package please send your details to given number. 8800393635 Card holders will get confirmation WhatsApp/SMS on them.registered mobile within 24 hours of receiving customers details.`,
            paragraph2: `2.Valid at Hotel only.`,
            paragraph3: `3.This membership package is valid for twelve months from the date of purchase.`,
            paragraph4: `4.This package is not valid on 26th January 14th February 15 August 24th, 25th and 31st December
            Holi. Diwali Wek and any special events. `,
            paragraph5: `5.Buy one get one offer is not valid on Sea foods &amp; side items like Indian breads, rice,
            Noodles, papad, and Salad.
            `,
            paragraph6: `6.All discounts are based on the published menu price.`,
            paragraph7: `7.Lost card cannot be replaced.`,
            paragraph8: `8.Mandatory Govt. Applicable Taxes &amp; other levies shall be extra.`,
            paragraph9: `9.Prices and menu items are subject to change without notice.`,
            paragraph10: `10.Please carry you dine-in and preferers leisure card to the respective outlets and present the
            card to the manager-in-charge at the time of placing the order, for the billing purpose, The offers will.
            not be entertained once the bill is printed.`,
            paragraph11: `11.In the event of any dispute or misunderstanding arising out of interpretation of any of the
            offers, the hotel management decision will be final and binding on the cardholders.
            `,
            paragraph12: `12.Management reserves the right to refuse admission to undisciplined customer in the`,
            paragraph13: `13.Interest of public safety and business reputation, your co-operation in this regard is much
            appreciated.
            `,
            paragraph14: `14.Each customer privilege Membership package will be entertained per visit /per table /
            per transaction for 15 members per day.
            `,
            paragraph15: `15.No verbal agreement accepted.`,
           
        }
       
    ]

}