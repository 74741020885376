<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="products-area ptb-100">
    <div class="container">
        <div class="marxa-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We found <span class="count">6</span> products available for you</p>
            </div>
            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of singleProductsBox | paginate: { itemsPerPage: 6, currentPage: productsList }">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/{{Content.detailsLink}}">
                            <img [src]="Content.image" class="main-image" alt="image">
                        </a>
                        <div class="sale-tag" *ngIf="Content.sale">{{Content.sale}}</div>
                        <div class="new-tag" *ngIf="Content.new">{{Content.new}}</div>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                        <div class="price">
                            <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                            <span class="new-price">{{Content.newPrice}}</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="productsList = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>