<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <!-- <div class="page-title-area"  style="background-color: #ff9500 !important;"> -->
    <div class="container">
        <h1>Register</h1>
    </div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>Login</h2>
                    <form  #loginform="ngForm" (ngSubmit)="login(loginform.value)">
                        <div class="form-group">
                            <label>email</label>
                            <input type="text" name="email" ngModel class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" name="password" ngModel class="form-control">
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">Remember me</label>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/" class="lost-your-password">Lost your password?</a>
                            </div>
                        </div>
                        <button type="submit">Log In</button>
                    </form>
                </div>
            </div> -->
            <div class="col-lg-6" style="float:none;margin:auto;">
                <div class="register-form">
                    <h2 style="text-align: center;">Register</h2>
                    <form [formGroup]="signUpForm">
                        <div class="form-group">
                            <label>Username</label>
                            <input type="text" formControlName="name" class="form-control">
                            <small *ngIf="signUpForm.controls['name'].dirty &&signUpForm.hasError('required','name')" class="text-danger">* Username is reqired</small>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email"  formControlName="email" class="form-control">
                            <small *ngIf="signUpForm.controls['email'].dirty &&signUpForm.hasError('required','email')" class="text-danger">* Email is reqired</small>
                        </div>
                        <div class="form-group">
                            <label>Mobile</label>
                            <input type="number"  formControlName="mobile"  class="form-control">
                            <small *ngIf="signUpForm.controls['mobile'].dirty &&signUpForm.hasError('required','mobile')" class="text-danger">* Mobile is reqired</small>
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" formControlName="password"  class="form-control">
                            <small *ngIf="signUpForm.controls['password'].dirty &&signUpForm.hasError('required','password')" class="text-danger">* Password is reqired</small>
                        </div>
                        <div class="form-group">
                            <label>Confirm Password</label>
                            <input type="password" formControlName="c_password" class="form-control">
                            <small *ngIf="signUpForm.controls['c_password'].dirty &&signUpForm.hasError('required','c_password')" class="text-danger">* Confirm Password is reqired</small>
                        </div>
                        <p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p>
                        <button type="submit" (click)="onSignup()">Register</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>