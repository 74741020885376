<!-- Start Join Now Area -->
<div class="join-now-area ptb-100">
    <div class="container">
        <div class="join-now-content" *ngFor="let Content of joinNowContent;">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
            <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a>
        </div>
    </div>
</div>
<!-- End Join Now Area -->