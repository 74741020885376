import { Component } from '@angular/core';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent {
  ngOnInit(): void {
  }

  pageTitle = [
    {
        bgImage: `assets/img/TripApna/image-header.jpg`,
        title: `Terms and Condition`,
    }
]
singleHowItWorkBox = [
    {
        title: `Terms & Conditions`,
        paragraph: `By using Tripapna, you unconditionally abide by the Terms & Conditions, without any restriction, that we at Tripapna have provided here for the use of this website. You are advised to agree to the outlined Terms & Conditions if you want to use this website. Please read the terms carefully during your wise use of Tripapna.`,

        title1: `Introduction`,
        paragraph1: `The information given here is ultimately a legal document and an agreement between Tripapna and the user. A registered company based in Singapore operates Tripapna. The content given below under the Terms & Conditions section is subject to amend or change at any point without any prior intimation. However, any further changes and amendments will be brought to your notice through this website.`,

        title2: `Classification of the Terms & Conditions`,
        paragraph2: `We at Tripapna provide discounted vouchers and coupons to all those merchants who act like a third party seller for us.`,
        paragraph21:'We offer complete information about our services through Tripapna (if you are using any coupon, you can redeem them at the respected merchant’s website).“Users” refer to an individual who visits and uses the website to avail any of our services.',
        title3: `General Issues`,
        paragraph3: `Accessibility to this website and using any of its services are solely subject to the mentioned Terms & Conditions.
        Services provided by Tripapna are designed for non-commercial and personal uses only.
        Tripapna is not responsible for the consequences arising after the use of the website and its services.`,

        title4: `User Obligations`,
        paragraph4: `If you are a user and agree to merchants’ Terms & Conditions, you will be re-directed to their webpage for a further transaction through Tripapna. As a user, you are also required to agree to the use of Tripapna website and its services to post and upload required data, materials and messages.`,

        
        title6: `Our Users Accept and Undertake the Following Points:`,
        paragraph6: `Not to publish any indecent and inappropriate data and material
        Not to copy or republish the same contents of the site on any other websites
        Not to upload any patent data or material
        Not to publish malicious material that may include corrupted files or software that may create a problem for the proper functioning of the website
        Not to violate legal rights, Terms & Conditions, and code of conduct that may hamper the image of the site.
        `,

        
    },
  ]
}
