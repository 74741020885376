import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-why-choose-us',
    templateUrl: './why-choose-us.component.html',
    styleUrls: ['./why-choose-us.component.scss']
})
export class WhyChooseUsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: `Why Buy on TripApna?`,
            paragraph: `On this platform we will provide you the membership cards of popular hotels & restaurants to our customers. We will promise of lowest price on listed properties.`
        }
    ]
    singleWhyChooseBox = [
        {
            icon: `bx bx-money`,
            title: `Money Back`,
            subTitle: `Refund in 30 days`
        },
        {
            icon: `bx bx-credit-card`,
            title: `Secure Payment`,
            subTitle: `No transaction fees`
        },
        {
            icon: `bx bxs-discount`,
            title: `Beat Hotel Package`,
            subTitle: `Top Hotel in India`
        }
    ]
    whyChooseContent = [
        {
            title: `Exciting Coupons for More Saving`,
            buttonText: `Join Now`,
            buttonLink: `contact`
        }
    ]

}