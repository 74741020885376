<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget" *ngFor="let Content of singleFooterWidget;">
                    <a routerLink="/" class="logo d-inline-block">
                        <img [src]="Content.logo" alt="image">
                    </a>
                    <p>{{Content.paragraph}}</p>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pl-4">
                    <h3>Useful Links</h3>
                    <ul class="links-list">
                        <li><a routerLink="/about-us">About Us</a></li>
                        <li><a routerLink="/how-it-works">Rule of Use</a></li>
                        <li><a routerLink="/privacypolicy">Privacy Policy</a></li>
                        <li><a routerLink="/cancellationandrefund">Cancellation & Refund</a></li>
                        <li><a routerLink="/termsandcondition">Terms & Conditions</a></li>
                    
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Quick link</h3>
                    <ul class="links-list">
                        <li><a routerLink="/coming-soon">Hotel Booking</a></li>
                        <li><a routerLink="/coming-soon">Flight Booking</a></li>
                        <li><a routerLink="/coming-soon">Taxi Booking</a></li>
                        <li><a routerLink="/all-stores">Hotel Package</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Address: 20 Lakshmi Lotus, Subhas road, Kumbhan Khan Pada, Dombivali (Thane), Maharastra - 421202</li>
                        <li>Email: <a href="mailto:hello@TripApna.com">hello@TripApna.com</a></li>
                        <li>Phone: <a href="tel:+4458895456">+44 458 895 456</a></li>
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>Copyright @{{ marxaYear }} <strong>Tripapna</strong> is Proudly Powered by Tripapna</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/customer-service">Privacy Policy</a></li>
                        <li><a routerLink="/customer-service">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>